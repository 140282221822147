<app-navbar></app-navbar>
<div class="container organization">
  <div
    class="col-10 offset-1"
    *ngIf="selectedOrganization"
  >
    <h3 class="text-center form-label o-breadcrumb">
      <a routerLink="/panel/organization/list">
        {{ 'app.panel.organization.component.allMyOrganizations' | translate }}
      </a>
      <span class="chevron">></span>
      <span class="font-weight-bold">{{ selectedOrganization.parsec_id }}</span>
    </h3>
  </div>
  <mat-card
    class="row align-items-center justify-content-center"
    *ngFor="let organization of organizations"
  >
    <div class="row no-gutters">
      <div class="form-label col-12">
        <span class="font-weight-bold">{{ organization.parsec_id }}</span> /
        <span class="font-italic">
          {{ 'app.panel.organization.component.createdAt' | translate }} {{ date.getLocaleString(organization.created_at) }}
        </span>
        /

        {{ getBillingSystemLabel() }}

        <span
          class="font-italic text-{{ getCustomOrderStatusColor(customOrderStatus[organization.parsec_id]) }}"
          *ngIf="billingSystem === BillingSystem.CustomOrder && isOrganizationLoaded(organization)"
          >({{ getCustomOrderStatusLabel(customOrderStatus[organization.parsec_id]) }})</span
        >
        <span
          class="font-italic"
          *ngIf="billingSystem === BillingSystem.ExperimentalCandidate"
        >
          ({{ getExpirationDateString(billingSystem, user.client.experimental_candidate_expiration_date) }})
        </span>
        <button
          mat-icon-button
          class="icon-tooltip"
          aria-label="billing system about"
          matTooltipPosition="right"
          (click)="$event.stopPropagation()"
          [matTooltip]="getBillingSystemTooltip(organization)"
          matTooltipClass="multiline-tooltip"
          *ngIf="isOrganizationLoaded(organization)"
        >
          <mat-icon>help</mat-icon>
        </button>
      </div>
    </div>
    <div class="row no-gutters">
      <mat-expansion-panel
        class="mat-elevation-z0 col-12"
        *ngIf="organization"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="font-weight-bold">
            {{ 'app.panel.organization.component.organizationInfo' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="organization && organizationsInfo[organization.pk] && organizationsInfo[organization.pk].loaded">
          <div class="row no-gutters">
            <div class="form-label italic col-12">{{ 'app.panel.organization.component.parsecStats' | translate }}</div>
          </div>
          <div
            class="row justify-content-left stats"
            *ngIf="!isEmptyObject(organizationsInfo[organization.pk].status)"
          >
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>info</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.organization.component.status.title' | translate }}</span>
                {{ getOrganizationStatus(organizationsInfo[organization.pk].status) }}
              </div>
            </div>
          </div>
          <div
            class="row justify-content-left stats"
            *ngIf="isEmptyObject(organizationsInfo[organization.pk].stats)"
          >
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>equalizer</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.organization.component.noAvailableStats' | translate }}</span>
              </div>
            </div>
          </div>
          <div
            class="row justify-content-center stats"
            *ngIf="!isEmptyObject(organizationsInfo[organization.pk].stats)"
          >
            <div class="col-6">
              <div
                class="d-flex"
                [ngClass]="{
                  'text-warning': isCustomOrderLimitValue(organization, 'STORAGE', 'warning'),
                  'text-danger': isCustomOrderLimitValue(organization, 'STORAGE', 'error')
                }"
              >
                <mat-icon>storage</mat-icon
                ><span class="font-weight-bold">{{ 'app.panel.organization.component.dataSize' | translate }}</span>
                {{ organizationsInfo[organization.pk].stats.readableDataSize }}
                <mat-icon *ngIf="getCustomOrderLimitValue(organization, 'STORAGE')">error_outline</mat-icon>
              </div>
              <div class="d-flex">
                <mat-icon>equalizer</mat-icon
                ><span class="font-weight-bold">{{ 'app.panel.organization.component.metadataSize' | translate }}</span>
                {{ organizationsInfo[organization.pk].stats.readableMetadataSize }}
              </div>
              <div class="d-flex">
                <mat-icon>people</mat-icon><span class="font-weight-bold">{{ 'app.panel.organization.component.users' | translate }}</span>
                {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.pk].stats.users.total }}
              </div>
            </div>
            <div class="col-6">
              <div
                class="d-flex"
                [ngClass]="{
                  'text-warning': isCustomOrderLimitValue(organization, 'ADMIN', 'warning'),
                  'text-danger': isCustomOrderLimitValue(organization, 'ADMIN', 'error')
                }"
              >
                <mat-icon>manage_accounts</mat-icon
                ><span class="font-weight-bold">{{ 'app.panel.organization.component.admins' | translate }}</span>
                {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.pk].stats.users.ADMIN }}
                <mat-icon *ngIf="getCustomOrderLimitValue(organization, 'ADMIN')">error_outline</mat-icon>
              </div>
              <div
                class="d-flex"
                [ngClass]="{
                  'text-warning': isCustomOrderLimitValue(organization, 'STANDARD', 'warning'),
                  'text-danger': isCustomOrderLimitValue(organization, 'STANDARD', 'error')
                }"
              >
                <mat-icon>person</mat-icon
                ><span class="font-weight-bold">{{ 'app.panel.organization.component.standards' | translate }}</span>
                {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.pk].stats.users.STANDARD }}
                <mat-icon *ngIf="getCustomOrderLimitValue(organization, 'STANDARD')">error_outline</mat-icon>
              </div>
              <div
                class="d-flex"
                [ngClass]="{
                  'text-warning': isCustomOrderLimitValue(organization, 'OUTSIDER', 'warning'),
                  'text-danger': isCustomOrderLimitValue(organization, 'OUTSIDER', 'error')
                }"
              >
                <mat-icon>person_outline</mat-icon
                ><span class="font-weight-bold">{{ 'app.panel.organization.component.outsiders' | translate }}</span>
                {{ 'app.panel.organization.component.userCount' | translate: organizationsInfo[organization.pk].stats.users.OUTSIDER }}
                <mat-icon *ngIf="getCustomOrderLimitValue(organization, 'OUTSIDER')">error_outline</mat-icon>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            billingSystem === BillingSystem.CustomOrder &&
            organization &&
            customOrderDetails[organization.pk] &&
            isOrganizationLoaded(organization)
          "
        >
          <div class="row no-gutters">
            <div class="form-label italic col-12">{{ 'app.panel.organization.component.customOrderDetails' | translate }}</div>
          </div>
          <div
            class="row justify-content-left stats"
            *ngIf="isEmptyObject(customOrderDetails[organization.pk])"
          >
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>receipt</mat-icon>
                <span class="font-weight-bold">{{ 'app.panel.organization.component.noAvailableContractDetails' | translate }}</span>
              </div>
            </div>
          </div>
          <div
            class="row justify-content-left stats"
            *ngIf="!isEmptyObject(customOrderDetails[organization.pk])"
          >
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>receipt</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.invoiceNumber' | translate }}
                </span>
                <a
                  target="_blank"
                  [href]="customOrderDetails[organization.pk]['link']"
                  [title]="'app.panel.organization.component.seeInSellsy' | translate"
                >
                  {{ customOrderDetails[organization.pk]['invoiceNumber'] }}
                </a>
              </div>
              <div class="d-flex">
                <mat-icon>payments</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.totalPrice' | translate }}
                </span>
                {{ customOrderDetails[organization.pk]['amount'] }}
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>manage_accounts</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.adminLicenses' | translate }}
                </span>
                {{ customOrderDetails[organization.pk]['licenses']['ADMIN'] }}
              </div>
              <div class="d-flex flex-wrap">
                <mat-icon>person</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.standardLicenses' | translate }}
                </span>
                {{ customOrderDetails[organization.pk]['licenses']['STANDARD'] }}
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>event_available</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.startDate' | translate }}
                </span>
                {{ customOrderDetails[organization.pk]['startDate'] }}
              </div>
              <div
                class="d-flex flex-wrap"
                [ngClass]="{
                  'text-warning': isCustomOrderLimitValue(organization, 'END_DATE', 'warning'),
                  'text-danger': isCustomOrderLimitValue(organization, 'END_DATE', 'error')
                }"
              >
                <mat-icon>event_busy</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.endDate' | translate }}
                </span>
                {{ customOrderDetails[organization.pk]['endDate'] }}
                <mat-icon *ngIf="getCustomOrderLimitValue(organization, 'END_DATE')">error_outline</mat-icon>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex">
                <mat-icon>hiking</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.outsiderLicenses' | translate }}
                </span>
                {{ customOrderDetails[organization.pk]['licenses']['OUTSIDER'] }}
              </div>
              <div class="d-flex flex-wrap">
                <mat-icon>storage</mat-icon>
                <span class="font-weight-bold">
                  {{ 'app.panel.organization.component.storageLicenses' | translate }}
                </span>
                {{ customOrderDetails[organization.pk]['licenses']['STORAGE'] }}
              </div>
            </div>
          </div>
          <div
            class="row justify-content-left stats"
            *ngIf="!isEmptyObject(customOrderDetails[organization.pk])"
          >
            <table
              mat-table
              [dataSource]="invoiceElementsDataSources[organization.pk]"
              *ngIf="invoiceElementsDataSources[organization.pk].length > 0"
              class="col-12"
            >
              <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  {{ 'app.panel.organization.component.contract.type' | translate }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="quantity">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  {{ 'app.panel.organization.component.contract.quantity' | translate }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.quantity }}
                </td>
              </ng-container>
              <ng-container matColumnDef="unitAmount">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  {{ 'app.panel.organization.component.contract.unitAmount' | translate }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.unitAmount }}
                </td>
              </ng-container>
              <ng-container matColumnDef="taxAmount">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  {{ 'app.panel.organization.component.contract.taxAmount' | translate }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.taxAmount }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalAmount">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                >
                  {{ 'app.panel.organization.component.contract.totalAmount' | translate }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                >
                  {{ element.totalAmount }}
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="invoiceElementsDisplayedColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: invoiceElementsDisplayedColumns"
              ></tr>
            </table>
          </div>
        </ng-container>
        <mat-spinner
          class="m-auto"
          *ngIf="!isOrganizationLoaded(organization)"
          diameter="40"
        ></mat-spinner>
      </mat-expansion-panel>
    </div>
    <div
      class="row justify-content-center"
      *ngIf="this.billingSystem === BillingSystem.Stripe && organization.stripe_subscription_id"
    >
      <div class="col-10 d-flex justify-content-end">
        <button
          mat-raised-button
          color="warn"
          (click)="unsubscribe(organization)"
        >
          {{ 'app.panel.organization.component.unsubscribe' | translate }}
        </button>
      </div>
    </div>
  </mat-card>

  <mat-paginator
    *ngIf="orgViewSet && (orgViewSet.previous || orgViewSet.next)"
    [length]="orgViewSet.count"
    [pageSize]="5"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
