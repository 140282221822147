import { Filesize } from 'src/app/shared/tools';
import { CustomOrderStatus } from '../admin.global';

export interface Status {
  expiration_date: boolean;
  is_bootstrapped: boolean;
  is_initialized: boolean;
  is_frozen: boolean;
}

export interface InvoiceElement {
  name: string;
  quantity: string;
  unitAmount: number | string;
  taxAmount: number | string;
  totalAmount: string;
}

export interface OrganizationData {
  parsec_id: string;
  created_at: string;
  expiration_date: string | null;
  client__billing_system: string;
}

interface OrganizationStats {
  users: {
    [key: string]: {
      active: number;
      revoked: number;
    };
  };
  dataSize: number;
  metadataSize: number;
  readableDataSize: string;
  readableMetadataSize: string;
}

export interface OrganizationInfo {
  status: object;
  stats: OrganizationStats | null;
}

interface OrganizationStatsResponse {
  active_users?: number;
  users?: number;
  data_size: number;
  metadata_size: number;
  users_per_profile_detail: {
    [key: string]: {
      active: number;
      revoked: number;
    };
  };
}

export interface OrganizationInfoResponse {
  status: object;
  stats: OrganizationStatsResponse | null;
}

export interface CustomOrderDetails {
  invoiceNumber: string;
  startDate: string;
  endDate: string;
  link: string;
  amount: string;
  licenses: {
    STANDARD: number;
    ADMIN: number;
    OUTSIDER: number;
    STORAGE: number;
  };
  rows: { string: InvoiceElement } | Record<string, never>;
}

export interface CustomOrderDetailsResponse {
  details: CustomOrderDetails;
  startDate: Date;
  endDate: Date;
}

// The response from the backend
export interface OrganizationListEntry {
  id: string;
  client: string;
  client__billing_system: string;
  client__company: string;
  client__firstname: string;
  client__lastname: string;
  client__user: string;
  created_at: string;
  expiration_date: string | null;
  parsec_id: string;
}

export interface Organization extends OrganizationListEntry {
  url: string;
  expiration_time: number | null;
  client__name: string;
}

export interface OrganizationListResponse {
  organizations: OrganizationListEntry[];
  custom_order_status: { [parsecId: string]: CustomOrderStatus };
}

interface SetExpirationDatePerOrganizationResponse {
  updated: boolean;
  frozen: boolean;
  frozen_changed: boolean;
}

export interface SetExpirationDateResponse {
  updated: number;
  organizations: { [organizationId: string]: SetExpirationDatePerOrganizationResponse };
}

export interface AlertAboutEndOfContractResponse {
  count: number;
}

export function getOrganizationStatsFromResponse({
  active_users = 0,
  users = 0,
  data_size = 0,
  metadata_size = 0,
  users_per_profile_detail = {},
}: OrganizationStatsResponse): OrganizationStats {
  return {
    users: {
      ...users_per_profile_detail,
      total: { active: active_users, revoked: users - active_users },
    },
    dataSize: data_size,
    metadataSize: metadata_size,
    readableDataSize: Filesize.toReadable(data_size),
    readableMetadataSize: Filesize.toReadable(metadata_size),
  };
}
