import { Component, OnDestroy, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DateService } from 'src/app/shared/date.service';
import { NotificationService, ReadNotificationEntry, UnreadNotificationEntry } from 'src/app/shared/notification.service';
import { notifications } from 'src/assets/referentials/notifications';

type Notification = {
  date: string;
  isUnread: boolean;
  message: {
    code: string;
    interpolateParams: any;
  };
  // From src/assets/referentials/notifications.ts
  type: 'info' | 'warning';
  link: string;
};

@Component({
  selector: 'app-notification-log',
  templateUrl: './notification-log.component.html',
  styleUrls: ['./notification-log.component.scss'],
})
export class NotificationLogComponent implements OnInit, OnDestroy {
  icons = {
    warning: 'warning',
    info: 'info',
  };
  unreads: Notification[] = [];
  reads: Notification[] = [];
  unreadsPageIndex = 0;
  readsPageIndex = 0;
  onUpdateSubscription: Subscription;

  constructor(
    public notification: NotificationService,
    public translate: TranslateService,
    public date: DateService,
  ) {}

  ngOnInit(): void {
    this.getNotifications({ enableUnreads: true, enableReads: true });
    this.onUpdateSubscription = this.notification.onUpdate.subscribe(() => {
      if (this.unreadsPageIndex + 1 > Math.ceil(this.notification.unreadsCount / 5) && this.unreadsPageIndex > 0) {
        this.unreadsPageIndex--;
      }
      if (this.readsPageIndex + 1 > Math.ceil(this.notification.readsCount / 5) && this.readsPageIndex > 0) {
        this.readsPageIndex--;
      }
      this.getNotifications({ enableUnreads: true, enableReads: true });
    });
  }

  getNotifications(options: { enableUnreads?: boolean; enableReads?: boolean }): void {
    if (options.enableReads) {
      this.reads = [];
    }
    if (options.enableUnreads) {
      this.unreads = [];
    }
    this.notification.update({
      unreads: { enable: options.enableUnreads, page: this.unreadsPageIndex },
      reads: { enable: options.enableReads, page: this.readsPageIndex },
      callback: () => {
        if (options.enableReads) {
          this.reads = this._formatNotifElements(this.notification.getReads());
        }
        if (options.enableUnreads) {
          this.unreads = this._formatNotifElements(this.notification.getUnreads());
        }
      },
    });
  }

  onReadsPageChange(event: PageEvent): void {
    this.readsPageIndex = event.pageIndex;
    this.getNotifications({ enableUnreads: false, enableReads: true });
  }

  onUnreadsPageChange(event: PageEvent): void {
    this.unreadsPageIndex = event.pageIndex;
    this.getNotifications({ enableUnreads: true, enableReads: false });
  }

  _formatNotifElements(notifs: (ReadNotificationEntry | UnreadNotificationEntry)[]): Notification[] {
    return notifs.map((element) => {
      const data = element.data;
      const interpolateParams = element.data;
      if (data.organization) {
        interpolateParams.organization.parsec_id = data.organization.parsec_id?.toUpperCase();
      }

      const notif: Notification = {
        ...notifications[element.code],
        pk: element.pk,
        isUnread: !element.is_read,
        date: element.created_at,
        message: {
          code: `referentials.notifications.${element.code}`,
          interpolateParams: interpolateParams,
        },
      };
      if (data.organization || data.organization_pk) {
        notif.link = notif.link.replace(':organization_id', data.organization.id || data.organization_pk);
      }

      return notif;
    });
  }

  toggleVisibility(): void {
    this.notification.toggleVisibility();
  }

  ngOnDestroy(): void {
    this.onUpdateSubscription.unsubscribe();
  }
}
